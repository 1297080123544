import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/appDownload',
		name: 'appDownload',
		component: () => import(/* webpackChunkName: "appDownload" */ '@/views/download/appDownload.vue')
	},
	{
		path: '/relatedAgreements',
		name: 'relatedAgreements',
		component: () => import(/* webpackChunkName: "appDownload" */ '@/views/relatedAgreements/index.vue')
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
